import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";
import { Link } from "react-router-dom";

import BasicTooltip from "components/Common/Tooltip/BasicTooltip";
import PageSidebarPanel from "components/Entities/Page/PageSidebarPanel";
import Title from "components/Entities/Page/Title";

import getEntityType from "utils/entity/getEntityType";

import useHasProForPodcast from "hooks/useHasProForPodcast";
import { useStyles } from "hooks/useStyles";
import userHasInternalPermission from "hooks/useUserHasInternalPermission";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  title: {
    ...gStyles.avalonBold, // TODO: This style should be shared between all sidebar titles & user profile titles
    color: "#292929",
    fontSize: "1rem",
    margin: "0 0 1.5rem",
    padding: 0,
  },
  valuesRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "-1.5rem",
  },
  valueColumn: {
    display: "flex",
    flexDirection: "column",
    flex: "0 1 50%",
    minWidth: "7rem",
    marginBottom: ".5rem",
    paddingRight: ".8rem",
  },
  valueColumnFullWidth: {
    flex: "1 100%",
    paddingRight: 0,
  },
  valueTitle: {
    ...gStyles.textEllipsis,
    ...gStyles.avalonBold,
    color: colours.black,
    fontSize: ".8125rem",
    marginBottom: ".5rem",
  },
  value: {
    ...gStyles.fontRegular,
    color: "#000",
    fontSize: ".8125rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  link: {
    ...gStyles.fontRegular,
    color: colours.primaryTextBackground,
  },
  infoIcon: {
    ...gStyles.resetButton,
    color: colours.greyishBlue,
    cursor: "pointer",
    fontSize: ".875rem",
    marginLeft: "auto",
    ":hover": {
      color: colours.greyishBlueDarker,
      outline: "none",
    },
    ":focus": {
      color: colours.greyishBlueDarker,
      outline: "none",
    },
    [ScreenSizes.lgAndAbove]: {
      fontSize: ".75rem",
    },
  },
};

const StatsPanel = (props) => {
  const {
    noPanel,
    title,
    entity,
    statsConfig,
    panelStyles,
    renderEmptyStatsMessage,
    renderEmptyStatsWithoutPanelMessage,
    hideEmptyStats,
    children,
    index,
    belowContent,
    renderOnMount,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const userIsInternal = userHasInternalPermission();

  const userHasPro = useHasProForPodcast(
    getEntityType(entity) === "podcast" && entity && entity.get("id")
  );

  const values = useMemo(
    () =>
      statsConfig.reduce((newValues, config) => {
        let formatted = null;

        if (!config.condition || config.condition(entity, userHasPro)) {
          if (config.format) {
            formatted = config.format(entity, userHasPro);
          } else if (config.renderContent) {
            formatted = config.renderContent(entity);
          }
        }

        if (formatted || (config.value && entity.get(config.value))) {
          return [
            ...newValues,
            {
              title: config.title,
              value: formatted || entity.get(config.value),
              fullWidth: config.fullWidth,
              to: config.to && config.to(entity, userIsInternal),
              hoverTitle:
                config.hoverTitle && config.hoverTitle(entity, userHasPro),
              infoMessage:
                config.infoMessage && config.infoMessage(entity, userHasPro),
              handleInfoClick: config.handleInfoClick,
              infoDataId: config.infoDataId,
            },
          ];
        }

        return newValues;
      }, []),
    [entity, statsConfig, userIsInternal, userHasPro]
  );

  const renderValueColumn = (columnProps, index) => {
    const {
      title: columnTitle,
      value,
      fullWidth,
      to,
      hoverTitle,
      infoMessage,
      handleInfoClick,
      infoDataId,
    } = columnProps;

    const infoButtonInner = (handleInfoClick || infoMessage) && (
      <button
        data-id={infoDataId}
        type="button"
        className={css(styles.infoIcon)}
        onClick={handleInfoClick || undefined}
        aria-label="Show Info"
      >
        <FontAwesomeIcon icon={faInfoCircle} />
      </button>
    );

    const infoButton = infoMessage ? (
      <BasicTooltip renderTooltip={() => infoMessage}>
        {(tooltipProps) => (
          <span {...tooltipProps} className={css(styles.tooltip)}>
            {infoButtonInner}
          </span>
        )}
      </BasicTooltip>
    ) : (
      infoButtonInner
    );

    return (
      <div
        data-id="stats-panel"
        key={index}
        className={css(
          styles.valueColumn,
          fullWidth && styles.valueColumnFullWidth
        )}
        title={hoverTitle}
      >
        <div
          data-id="stat-title"
          className={css(styles.valueTitle)}
          title={columnTitle}
        >
          {columnTitle}
        </div>
        {to ? (
          <Link
            data-id="stat-value"
            className={css(styles.value, styles.link)}
            to={to}
          >
            {value}
            {infoButton}
          </Link>
        ) : (
          <div data-id="stat-value" className={css(styles.value)}>
            {value}
            {infoButton}
          </div>
        )}
      </div>
    );
  };

  if (hideEmptyStats && values.length === 0) {
    return null;
  }
  if (renderEmptyStatsWithoutPanelMessage && values.length === 0) {
    return renderEmptyStatsWithoutPanelMessage();
  }

  const renderContent = () => (
    <>
      <Title title={title} TitleComponent="h3" inSidebar />
      <div className={css(styles.valuesRow)}>
        {renderEmptyStatsMessage && values.length === 0
          ? renderEmptyStatsMessage()
          : values.map(renderValueColumn)}
      </div>
    </>
  );

  if (noPanel) {
    return (
      <Fragment>
        {children}
        {renderContent()}
      </Fragment>
    );
  }

  return (
    <PageSidebarPanel
      styles={panelStyles}
      index={index}
      renderOnMount={renderOnMount}
    >
      {children}
      {renderContent()}
      {belowContent}
    </PageSidebarPanel>
  );
};

StatsPanel.propTypes = {
  statsConfig: PropTypes.array,
  entity: PropTypes.instanceOf(Map),
  noPanel: PropTypes.bool,
  title: PropTypes.string,
  panelStyles: PropTypes.object,
  renderEmptyStatsWithoutPanelMessage: PropTypes.func,
  renderEmptyStatsMessage: PropTypes.func,
  hideEmptyStats: PropTypes.bool,
  renderOnMount: PropTypes.bool,
  children: PropTypes.node,
  index: PropTypes.number,
  belowContent: PropTypes.node,
};

StatsPanel.defaultProps = {
  entity: Map({}),
  statsConfig: [],
  noPanel: false,
  title: "Stats",
  panelStyles: null,
  renderEmptyStatsWithoutPanelMessage: null,
  renderEmptyStatsMessage: null,
  hideEmptyStats: false,
  children: null,
  index: 0,
  belowContent: null,
};

export default StatsPanel;
